.container{
    margin: 10px;
}
.why{
    font-weight: 800;
}
.whyh{
    color: darkred;
    font-weight: 700;
    text-shadow: 2px 3px 3px rgb(184, 56, 77);
}
/* Animation for fading in from the left */
.fadeInLeft{
    box-shadow: 2px 3px 4px 5px rgb(184, 56, 77);
    margin-bottom: 25px;
    text-align: center;
}
.fadeInRight{
    box-shadow: 3px 4px 5px 6px ;
    margin-bottom: 30px;
    text-align: center;
}
  