.imgaa{
    width: 100%;
    height: 90vh;
    position: relative;
  display: inline-block;
}
.hero-logo{
    width: 50%;
    height: 50% ;
}
.carousel-item{
    position: relative;
  /* display:inline-block; */
}
.carousel-item:after{
        content: '';
        position: absolute;
        height: 100vh;
        left:0; top:0;
        /* display:inline-block; */
        width: 100%;
        background: rgb(0,0,0,0.4);
}
/* .carousel-item img{
    display: block;
} */
.carousel-inner{
    position: relative;
}
.hero-image{
    text-align: center;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  z-index: 200;
  width: 80%;
}
.hero-image h1{
    font-weight: 700;
    font-size: 50px;    
}
.hero-button{
    width: 50%;
    height: 50px;
    background-color: rgb(202, 37, 37);
    border-radius: 15px;
    color: white;
    font-size: 30px;
}
.hero-button:hover{
    background-color: black;
    color: rgb(202, 37, 37);
}
@media screen and (max-width: 480px) {
    .hero-image h1{
    font-weight: 500;
    font-size: 25px;    
}
.carousel-indicators{
    height: 12vh;
}
.hero-button{
    width: 80%;
    height: 70px;
    background-color: rgb(202, 37, 37);
    border-radius: 15px;
    color: white;
    font-size: 20px;
}
}
