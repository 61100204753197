.google-review-slider {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.slider-content {
  display: flex;
  width: 100%;
  transition: transform 1s ease-in-out;
}

.review-card {
  flex: 0 0 100%;
  padding: 20px;
  text-align: center;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
}

.rating {
  margin-top: 10px;
  font-weight: bold;
}
.review,
.card {
  box-shadow: 2px 3px 5px 6px pink;
  /* border: 3px solid red; */
}

/* Add more styling as needed */
