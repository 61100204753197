@media screen and (max-width: 480px) {
  .about-us {
    /* background-color: red; */
    display: float;
    box-shadow: 2px 4px 2px 4px;
    /* justify-content: space-around; */
    padding: 20px;
    text-align: justify;
  }
  .image {
    flex: 1;
    border-radius: 50%;
    /* height: 50%; */
    width: 70%;
    border: 2px solid red;
    overflow: hidden;
    box-shadow: 5px 2px 6px 8px;
  }
  .image img {
    width: 100%;
    height: 180px;
    display: block;
  }
  .about-h {
    margin: 10px;
    color: red;
    font-weight: 700;
  }
  .about-paragraph span {
    color: rgb(158, 42, 42);
    font-weight: 800;
  }
}
